import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({ providedIn: 'root'})
export class AuthGuard  {

  constructor(
    private readonly _authService: AuthService,
    private readonly _logger: NGXLogger,
    private readonly _router: Router
  ) { }

  private handleAuthentication(isAuthenticated: boolean, state: RouterStateSnapshot): void {
    this._logger.debug('[AuthGuard].[handleAuthentication]', isAuthenticated, state);

    if (!isAuthenticated) {
      this._router.navigate(['auth', 'login'], {
        queryParams: {
          returnUrl: state.url
        }
      });
    }
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this._logger.debug('[AuthGuard].[canActivate]', next, state);

    return this._authService.isAuthenticated()
      .pipe(
        take(1),
        tap(isAuthenticated => this.handleAuthentication(isAuthenticated, state))
      );
  }

}
